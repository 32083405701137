import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import NetworkError from 'shared/exceptions/network';
import {
  getAuthUserData,
  removeAuthUserData,
  setAuthUserData,
} from 'shared/helpers/auth';
import authModel from 'store/models/auth';
import initialState from './initial';
import { TAuthResponse, TUserResponse } from './types';
import { getProfileFormChangedData } from 'features/Profile/model/selectors/getProfileForm';
import { TState } from 'store/types';
import { getSmsOtp } from 'entities/AuthBlock/model/selectors/getAuthBlock';
import { ProfileFormActions } from 'features/Profile';
import { authBlockActions } from 'entities/AuthBlock';
import { sendMetrik, sendPixel } from 'shared/helpers/sendMetrik/yandexMetrik';
import CONFIG from 'shared/constants/config';
import { refferalActions } from 'pages/cabinet/profile/profileReferral/model/store';
import {
  TPartnerType,
  TPromocodes,
  TPromocodesRequest,
  TPromocodesUpdateRequest,
} from 'pages/cabinet/profile/profileReferral/model/schema';

//POST
export const fetchAuth = createAsyncThunk(
  'auth/fetchAuth',
  async (
    data: { [key: string]: string | number | undefined },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const response = (await authModel.postAuth(
        data,
      )) as unknown as TAuthResponse;
      const IS_DEV = process.env.REACT_APP_NODE_ENV === 'development';
      if (data?.endpoint === 'register' && !IS_DEV) {
        sendMetrik('reachGoal', 'registration');
        sendPixel('reachGoal', 'registration');
      }
      document.cookie = `${CONFIG.PROMO_COOKIE_NAME}=undefined; max-age=0; path=/`;
      dispatch(ProfileFormActions.setIsOpenSms(false));
      return response;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const fetchBitrix24Login = createAsyncThunk(
  'auth/fetchBitrix24Login',
  async (
    data: { data_token: string; phone?: string; code?: string },
    { rejectWithValue },
  ) => {
    try {
      const response = (await authModel.bitrix24Login(
        data,
      )) as unknown as TAuthResponse;
      return response;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const fetchOzonLogin = createAsyncThunk(
  'auth/fetchOzonLogin',
  async (data: { data_token: string }, { rejectWithValue }) => {
    try {
      const response: TAuthResponse = await authModel.ozonLogin(data);
      return response;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const fetchUser = createAsyncThunk(
  'auth/fetchUser',
  async (repeat: boolean | undefined, { rejectWithValue, dispatch }) => {
    try {
      const response = (await authModel.getUser()) as unknown as TUserResponse;
      dispatch(refferalActions.setInit());
      setTimeout(() => {
        dispatch(fetchUser(true));
      }, 300000);
      return response;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

//PUT
export const putProfileForm = createAsyncThunk(
  'auth/putUser',
  async (_, { rejectWithValue, getState, dispatch }) => {
    const profileData = getProfileFormChangedData(getState() as TState);
    const smsOtp = getSmsOtp(getState() as TState);
    try {
      const response = (await authModel.putUser({
        ...profileData,
        code: smsOtp ? smsOtp : undefined,
      })) as unknown as TUserResponse;
      dispatch(ProfileFormActions.setIsOpenSms(false));
      dispatch(ProfileFormActions.setInitialData(response.data));
      dispatch(ProfileFormActions.setChangedData(response.data));
      dispatch(authBlockActions.clearSms());
      return response;
    } catch (e) {
      dispatch(authBlockActions.setSmsError(e));
      dispatch(authBlockActions.setSmsOtp(''));
      return rejectWithValue(e);
    }
  },
);

export const createPartnerForm = createAsyncThunk(
  'auth/createPartnerForm',
  async (data: TPromocodesRequest, { rejectWithValue }) => {
    try {
      const response = await authModel.postPartner<{ data: TPromocodes }>(data);
      return response;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const updatePartnerForm = createAsyncThunk(
  'auth/updatePartnerForm',
  async (data: TPromocodesUpdateRequest, { rejectWithValue }) => {
    try {
      const response = await authModel.putPartner<{ data: TPromocodes }>(data);
      return response;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const fetchSms = createAsyncThunk(
  'auth/fetchSms',
  async (
    data: { [key: string]: string | number | undefined },
    { rejectWithValue },
  ) => {
    try {
      const response = await authModel.postAuth(data);
      return response as unknown as {
        data: { message: string; registered: boolean };
      };
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    initializeAuthUser(state) {
      const isAuth = !!getAuthUserData();
      state.isAuth = isAuth;
    },
    logoutUser(state) {
      removeAuthUserData();
      state.isAuth = false;
      state.user.response = null;
    },
    setPostCount(state, action) {
      if (state.user.response && state.user.response.data) {
        state.user.response.data.all_polls_count = action.payload;
      }
    },
    setClientBalance(state, action: PayloadAction<number>) {
      if (state.user.response && state.user.response.data) {
        state.user.response.data.client_balance = action.payload.toString();
      }
    },
    setPartnerType(state, action: PayloadAction<TPartnerType | null>) {
      state.partner.data.type = action.payload;
    },
    setPartnerCode(state, action: PayloadAction<string | null>) {
      state.partner.data.code = action.payload;
    },
    setPartnerName(state, action: PayloadAction<string | null>) {
      state.partner.data.name = action.payload;
    },
    setPartnerPassportNum(state, action: PayloadAction<string | null>) {
      state.partner.data.passport_num = action.payload;
    },
    setPartnerPassportDate(state, action: PayloadAction<string | null>) {
      state.partner.data.passport_date = action.payload;
    },
    setPartnerPassportIssued(state, action: PayloadAction<string | null>) {
      state.partner.data.passport_issued = action.payload;
    },
    setPartnerInn(state, action: PayloadAction<string | null>) {
      state.partner.data.inn = action.payload;
    },
    setPartnerSnils(state, action: PayloadAction<string | null>) {
      state.partner.data.snils = action.payload;
    },
    setPartnerAddress(state, action: PayloadAction<string | null>) {
      state.partner.data.address = action.payload;
    },
    setBankName(state, action: PayloadAction<string | null>) {
      state.partner.data.bank_name = action.payload;
    },
    setBankBic(state, action: PayloadAction<string | null>) {
      state.partner.data.bank_bic = action.payload;
    },
    setBankCorAcc(state, action: PayloadAction<string | null>) {
      state.partner.data.bank_cor_acc = action.payload;
    },
    setBankAcc(state, action: PayloadAction<string | null>) {
      state.partner.data.bank_acc = action.payload;
    },
    clearIsNewPartnerCreated(state) {
      state.partner.isNewPArtnerCreated = false;
    },
    //Ozon
    setOzonAuth(state) {
      state.ozonAuth.isOzonAuth = true;
    },
    setOzonError(state) {
      state.ozonAuth.errorCustomMessage = 'Пользователь не зарегистрирован';
    },
    clearOzonError(state) {
      state.ozonAuth.error = undefined;
    },
  },

  extraReducers: builder => {
    //POST
    //auth/fetchAuth
    builder.addCase(fetchAuth.pending, state => {
      state.auth.loading = true;
      state.auth.error = null;
    });

    builder.addCase(fetchAuth.fulfilled, (state, { payload }) => {
      state.auth.loading = false;
      state.auth.response = payload;
      state.isAuth = true;
      setAuthUserData(payload.data.token);
    });

    builder.addCase(fetchAuth.rejected, (state, action) => {
      state.auth.loading = false;
      const message = (action.payload as NetworkError).message;
      state.auth.error = { ...(action.payload as NetworkError), message };
    });

    //auth/fetchBitrix24Login
    builder.addCase(fetchBitrix24Login.pending, state => {
      state.auth.loading = true;
      state.bitrixAuth.error = undefined;
    });

    builder.addCase(fetchBitrix24Login.fulfilled, (state, { payload }) => {
      state.auth.loading = false;
      state.auth.response = payload;
      state.bitrixAuth.error = undefined;
      if (payload?.data?.token) {
        state.isAuth = true;
        state.bitrixAuth.isBitrixAuth = true;
        setAuthUserData(payload.data.token);
      } else {
        state.bitrixAuth.response = 'NEW USER';
      }
    });

    builder.addCase(fetchBitrix24Login.rejected, (state, action) => {
      state.auth.loading = false;
      const message = (action.payload as NetworkError).message;
      state.bitrixAuth.error = { ...(action.payload as NetworkError), message };
    });

    //auth/fetchOzonLogin
    builder.addCase(fetchOzonLogin.pending, state => {
      state.auth.loading = true;
      state.ozonAuth.error = undefined;
    });

    builder.addCase(fetchOzonLogin.fulfilled, (state, { payload }) => {
      state.auth.loading = false;
      state.auth.response = payload;
      state.ozonAuth.error = undefined;
      if (payload?.data?.token) {
        state.isAuth = true;
        state.ozonAuth.isOzonAuth = true;
        setAuthUserData(payload.data.token);
      }
      // else {
      //   state.bitrixAuth.response = 'NEW USER';
      // }
    });

    builder.addCase(fetchOzonLogin.rejected, (state, action) => {
      state.auth.loading = false;
      // state.ozonAuth.isOzonAuth = true;
      const message = (action.payload as NetworkError).message;
      state.ozonAuth.error = { ...(action.payload as NetworkError), message };
    });

    //auth/fetchUser
    builder.addCase(fetchUser.pending, state => {
      state.user.loading = true;
      state.user.error = null;
    });

    builder.addCase(fetchUser.fulfilled, (state, { payload, meta }) => {
      state.user.loading = false;
      state.user.error = null;
      state.user.response = payload;
      if (payload.data.promocodes?.[0] && !meta.arg) {
        state.partner.data = payload.data.promocodes?.[0];
        state.partner.initialData = payload.data.promocodes?.[0];
      }
    });

    builder.addCase(fetchUser.rejected, (state, action) => {
      state.user.loading = false;
      const message = (action.payload as NetworkError).message;
      state.user.error = { ...(action.payload as NetworkError), message };
    });

    //auth/putUser
    builder.addCase(putProfileForm.pending, state => {
      state.user.loading = true;
      state.user.error = null;
    });

    builder.addCase(putProfileForm.fulfilled, (state, { payload }) => {
      state.user.loading = false;
      state.user.error = null;
      state.user.response = payload;
      if (payload.data.promocodes?.[0]) {
        state.partner.data = payload.data.promocodes?.[0];
        state.partner.initialData = payload.data.promocodes?.[0];
      }
    });

    builder.addCase(putProfileForm.rejected, (state, action) => {
      state.user.loading = false;
      const message = (action.payload as NetworkError).message;
      state.user.error = { ...(action.payload as NetworkError), message };
    });

    //auth/fetchSms
    builder.addCase(fetchSms.pending, state => {
      state.sms.loading = true;
      state.sms.error = null;
    });

    builder.addCase(fetchSms.fulfilled, (state, { payload }) => {
      state.sms.loading = false;
      state.sms.response = payload.data;
    });

    builder.addCase(fetchSms.rejected, (state, action) => {
      state.sms.loading = false;
      const message = (action.payload as NetworkError).message;
      state.sms.error = { ...(action.payload as NetworkError), message };
    });
    //auth/createPartnerForm
    builder.addCase(createPartnerForm.pending, state => {
      state.partner.loading = true;
      state.partner.error = undefined;
    });

    builder.addCase(createPartnerForm.fulfilled, (state, { payload }) => {
      state.partner.loading = false;
      state.partner.error = undefined;
      if (state.user.response?.data.promocodes) {
        state.user.response.data.promocodes = [payload.data];
        state.partner.data = payload.data;
        state.partner.initialData = payload.data;
      }
      state.partner.isNewPArtnerCreated = true;
    });

    builder.addCase(createPartnerForm.rejected, (state, action) => {
      state.partner.loading = false;
      const message = (action.payload as NetworkError).message;
      state.partner.error = { ...(action.payload as NetworkError), message };
    });

    //auth/updatePartnerForm
    builder.addCase(updatePartnerForm.pending, state => {
      state.partner.loading = true;
      state.partner.error = undefined;
    });

    builder.addCase(updatePartnerForm.fulfilled, (state, { payload }) => {
      state.partner.loading = false;
      state.partner.error = undefined;
      if (state.user.response?.data.promocodes) {
        state.user.response.data.promocodes = [payload.data];
        state.partner.data = payload.data;
        state.partner.initialData = payload.data;
      }
    });

    builder.addCase(updatePartnerForm.rejected, (state, action) => {
      state.partner.loading = false;
      const message = (action.payload as NetworkError).message;
      state.partner.error = { ...(action.payload as NetworkError), message };
    });
  },
});

export default authSlice.reducer;
export const {
  initializeAuthUser,
  logoutUser,
  setPostCount,
  setClientBalance,
  setPartnerType,
  setPartnerCode,
  setPartnerName,
  setPartnerPassportNum,
  setPartnerPassportDate,
  setPartnerPassportIssued,
  setPartnerInn,
  setPartnerSnils,
  setPartnerAddress,
  setBankName,
  setBankBic,
  setBankCorAcc,
  setBankAcc,
  clearIsNewPartnerCreated,
  setOzonAuth,
  setOzonError,
  clearOzonError,
} = authSlice.actions;
