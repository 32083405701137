import Network, { TAxios } from 'shared/libraries/network';
import CONFIG from 'shared/constants/config';
import { TUser } from 'pages/user/store/types';
import {
  TPromocodes,
  TPromocodesRequest,
  TPromocodesUpdateRequest,
} from 'pages/cabinet/profile/profileReferral/model/schema';

class AuthModel extends Network {
  constructor(baseURL: string, timeout = 120000) {
    super(baseURL, timeout);
  }

  async getLogOut(): Promise<TAxios> {
    return this.send('get', `/logout`);
  }

  async getUser(): Promise<TAxios> {
    return this.send('get', `/user`);
  }

  async checkPromo(promocode: string): Promise<TAxios> {
    return this.send('get', `/promo`, { params: { promocode } });
  }

  async putUser(data: TUser): Promise<TAxios> {
    return this.send('put', `/user`, data);
  }

  async postPartner<T>(data: TPromocodesRequest): Promise<T> {
    return this.send('post', `/promocodes`, data);
  }

  async putPartner<T>(data: TPromocodesUpdateRequest): Promise<T> {
    const { promocodeId, ...rest } = data;
    return this.send('put', `/promocodes/${promocodeId}`, rest);
  }

  async postAuth(data: {
    [key: string]: string | number | undefined;
  }): Promise<TAxios> {
    const { endpoint, ...rest } = data;
    return this.send('post', `/${endpoint}`, {
      ...rest,
    });
  }

  async bitrix24Login(data: {
    data_token: string;
    phone?: string;
    code?: string;
  }): Promise<TAxios> {
    return this.send('post', `/bitrix/login`, {
      ...data,
    });
  }

  async ozonLogin(data: { data_token: string }): Promise<TAxios> {
    return this.send('post', `/ozon/login`, {
      ...data,
    });
  }
}

const baseUrl = CONFIG.REACT_APP_API_BASE_URL as string;
const authModel = new AuthModel(baseUrl);

export default authModel;
