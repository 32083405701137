import React, { FC, useEffect } from 'react';
import withProviders from './providers';
import Routing from '../pages';

import './styles/index.scss';
import { initializeAuthUser } from 'pages/user/store/store';
import { useAppDispatch } from 'shared/helpers/hooks/useAppDispatch/useAppDispatch';
import { ContactSupportModal } from 'features/ContactSupport';
import { useSelector } from 'react-redux';
import { getOzonAuth } from 'store/selectors/user';
import { AUTH_TOKEN } from 'shared/constants/commmon';

const App: FC = () => {
  const dispatch = useAppDispatch();
  dispatch(initializeAuthUser());
  const { isOzonAuth } = useSelector(getOzonAuth);

  useEffect(() => {
    window.addEventListener('error', e => {
      // prompt user to confirm refresh
      if (/Loading chunk [\d]+ failed/.test(e.message)) {
        window.location.reload();
      }
    });
  }, []);

  // useEffect(() => {
  //   const handleBeforeUnload = event => {
  //     event.preventDefault(); // Это нужно для совместимости с некоторыми браузерами

  //     if (isOzonAuth) {
  //       // Удаляем конкретную запись из localStorage
  //       localStorage.removeItem(AUTH_TOKEN);
  //     }
  //   };

  //   if (isOzonAuth) {
  //     window.addEventListener('beforeunload', handleBeforeUnload);
  //   }

  //   // Очистка при размонтаже компонента (необязательно)
  //   return () => {
  //     if (isOzonAuth) {
  //       window.removeEventListener('beforeunload', handleBeforeUnload);
  //     }
  //   };
  // }, [isOzonAuth]);

  return (
    <>
      <Routing />
      <ContactSupportModal />
    </>
  );
};

const WrapProviders: FC = () => {
  return (
    <>
      <App />
    </>
  );
};

export default withProviders(WrapProviders);
