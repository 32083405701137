import { acceptedFilesExt } from 'shared/components/dropZone';
import { TCompareItem } from './components/CompareItem/CompareItem';
import { TQuoteItem } from './components/QuoteItem/QuoteItem';
import { TTarifItem } from './components/TarifItem/TarifItem';

const DEFAULT_PATH = '/img/indexPage';
export const getDefaultPath = (fileName: string) =>
  `${DEFAULT_PATH}/${fileName}`;
const imgPath = `${DEFAULT_PATH}/testItems`;

export const CreateListSteps = [
  {
    id: 'couple',
    title: <>Выберите тип&nbsp;теста</>,
    text: (
      <>
        Один из&nbsp;двух, приоритетный, звездный, открытый вопрос,
        клик&#8209;тест, турнирный
      </>
    ),
  },
  {
    id: 'range',
    title: `Напишите вопрос`,
    text: `Алгоритм подскажет статистически нейтральную формулировку`,
  },
  {
    id: 'stars',
    title: `Загрузите материалы`,
    text: (
      <>
        Подойдут изображения в&nbsp;современных графических форматах{' '}
        {acceptedFilesExt}
      </>
    ),
  },
  {
    id: 'open',
    title: `Выберите аудиторию`,
    text: (
      <>
        Укажите необходимое количество респондентов, их&nbsp;пол, возраст
        и&nbsp;уровень образования
      </>
    ),
  },
  {
    id: 'click',
    title: (
      <>
        Запустите <br /> тест
      </>
    ),
    text: (
      <>
        Алгоритм отберет респондентов с&nbsp;необходимыми параметрами
        из&nbsp;базы и&nbsp;проведет тест
      </>
    ),
  },
  {
    id: 'tournament',
    title: (
      <>
        Получите <br /> отчет
      </>
    ),
    text: (
      <>
        Алгоритм сформирует детальный и&nbsp;наглядный отчет, достаточный
        для&nbsp;принятия решения
      </>
    ),
  },
];

export const CompareItems: TCompareItem[] = [
  {
    title: `Маркетплейсы`,
    descr: `Оценка основного изображения для карточки товара`,

    data: [
      [
        {
          link: `${imgPath}/test-marketplaces-image_01-a.jpg`,
          votes: 23,
        },
        {
          link: `${imgPath}/test-marketplaces-image_01-b.jpg`,
          votes: 77,
        },
      ],
      [
        {
          link: `${imgPath}/test-marketplaces-image_02-a.jpg`,
          votes: 63,
        },
        {
          link: `${imgPath}/test-marketplaces-image_02-b.jpg`,
          votes: 37,
        },
      ],
      [
        {
          link: `${imgPath}/test-marketplaces-image_03-a.jpg`,
          votes: 63,
        },
        {
          link: `${imgPath}/test-marketplaces-image_03-b.jpg`,
          votes: 37,
        },
      ],
    ],
  },
  {
    title: `FMCG`,
    descr: `Оценка упаковки и элементов дизайна от формы до этикетки `,
    data: [
      [
        {
          link: `${imgPath}/test-fmcg-image_01-a.jpg`,
          votes: 41,
        },
        {
          link: `${imgPath}/test-fmcg-image_01-b.jpg`,
          votes: 59,
        },
      ],
      [
        {
          link: `${imgPath}/test-fmcg-image_02-a.jpg`,
          votes: 47,
        },
        {
          link: `${imgPath}/test-fmcg-image_02-b.jpg`,
          votes: 53,
        },
      ],
      [
        {
          link: `${imgPath}/test-fmcg-image_03-b.jpg`,
          votes: 61,
        },
        {
          link: `${imgPath}/test-fmcg-image_03-a.jpg`,
          votes: 39,
        },
      ],
    ],
  },
  {
    title: `Игры`,
    descr: `Оценка пиктограммы игры, дизайна персонажа или локации`,
    data: [
      [
        {
          link: `${imgPath}/test-games-image_01-a.jpg`,
          votes: 86,
        },
        {
          link: `${imgPath}/test-games-image_01-b.jpg`,
          votes: 14,
        },
      ],
      [
        {
          link: `${imgPath}/test-games-image_02-a.jpg`,
          votes: 61,
        },
        {
          link: `${imgPath}/test-games-image_02-b.jpg`,
          votes: 39,
        },
      ],
      [
        {
          link: `${imgPath}/test-games-image_03-a.jpg`,
          votes: 51,
        },
        {
          link: `${imgPath}/test-games-image_03-b.jpg`,
          votes: 49,
        },
      ],
      [
        {
          link: `${imgPath}/test-games-image_04-a.jpg`,
          votes: 56,
        },
        {
          link: `${imgPath}/test-games-image_04-b.jpg`,
          votes: 44,
        },
      ],
      [
        {
          link: `${imgPath}/test-games-image_05-a.jpg`,
          votes: 86,
        },
        {
          link: `${imgPath}/test-games-image_05-b.jpg`,
          votes: 14,
        },
      ],
    ],
  },
  {
    title: `Маркетинг`,
    descr: `Оценка дизайна логотипа и элементов фирменного стиля`,
    data: [
      [
        {
          link: `${imgPath}/test-marketing-image_01-a.jpg`,
          votes: 92,
        },
        {
          link: `${imgPath}/test-marketing-image_01-b.jpg`,
          votes: 8,
        },
      ],
      [
        {
          link: `${imgPath}/test-marketing-image_02-a.jpg`,
          votes: 51,
        },
        {
          link: `${imgPath}/test-marketing-image_02-b.jpg`,
          votes: 49,
        },
      ],
      [
        {
          link: `${imgPath}/test-marketing-image_03-a.jpg`,
          votes: 65,
        },
        {
          link: `${imgPath}/test-marketing-image_03-b.jpg`,
          votes: 35,
        },
      ],
      [
        {
          link: `${imgPath}/test-marketing-image_04-a.jpg`,
          votes: 35,
        },
        {
          link: `${imgPath}/test-marketing-image_04-b.jpg`,
          votes: 65,
        },
      ],
    ],
  },
  {
    title: `Соцсети`,
    descr: `Оценка графики обложек для постов (стиль, ракурс, элементы)`,
    data: [
      [
        {
          link: `${imgPath}/test-social-image_01-a.jpg`,
          votes: 72,
        },
        {
          link: `${imgPath}/test-social-image_01-b.jpg`,
          votes: 28,
        },
      ],
      [
        {
          link: `${imgPath}/test-social-image_02-a.jpg`,
          votes: 46,
        },
        {
          link: `${imgPath}/test-social-image_02-b.jpg`,
          votes: 54,
        },
      ],
      [
        {
          link: `${imgPath}/test-social-image_03-a.jpg`,
          votes: 54,
        },
        {
          link: `${imgPath}/test-social-image_03-b.jpg`,
          votes: 46,
        },
      ],
      [
        {
          link: `${imgPath}/test-social-image_04-a.jpg`,
          votes: 55,
        },
        {
          link: `${imgPath}/test-social-image_04-b.jpg`,
          votes: 45,
        },
      ],
    ],
  },
  {
    title: `UI / UX`,
    descr: `Оценка пользовательского интерфейса и его элементов`,
    data: [
      [
        {
          link: `${imgPath}/test-ui-image_01-a.jpg`,
          votes: 68,
        },
        {
          link: `${imgPath}/test-ui-image_01-b.jpg`,
          votes: 32,
        },
      ],
      [
        {
          link: `${imgPath}/test-ui-image_02-a.jpg`,
          votes: 53,
        },
        {
          link: `${imgPath}/test-ui-image_02-b.jpg`,
          votes: 47,
        },
      ],
      [
        {
          link: `${imgPath}/test-ui-image_03-a.jpg`,
          votes: 54,
        },
        {
          link: `${imgPath}/test-ui-image_03-b.jpg`,
          votes: 46,
        },
      ],
      [
        {
          link: `${imgPath}/test-ui-image_04-a.jpg`,
          votes: 68,
        },
        {
          link: `${imgPath}/test-ui-image_04-b.jpg`,
          votes: 32,
        },
      ],
    ],
  },
];

export const QuoteItems: TQuoteItem[] = [
  {
    text: (
      <>
        Сервис теперь постоянный участник наших совещаний с&nbsp;дизайнерами.
        Как только возникают вопросы, недопонимания или споры, мы&nbsp;просто
        обращаемся к&nbsp;целевой аудитории и&nbsp;получаем релевантные ответы
      </>
    ),
    author: 'Dr. SEA',
  },
  {
    text: (
      <>
        Для тестирования дизайна новых товаров и&nbsp;проверки гипотез,
        мы&nbsp;искали решение, которое было&nbsp;бы не&nbsp;только экономически
        целесообразным, но&nbsp;и&nbsp;позволило&nbsp;бы быстро получать
        результаты. SplitTest с&nbsp;этим справляется на&nbsp;все 100!
      </>
    ),
    author: 'SPLAT',
  },
  {
    text: (
      <>
        Мы&nbsp;начали пользоваться сервисом одними из&nbsp;первых и&nbsp;уже
        проверили несколько гипотез. Прекрасно, что в&nbsp;России появился
        настолько удобный и&nbsp;простой сервис, который может помочь селлерам
        протестировать CTR карточки без запуска ненужных рекламных кампаний
        внутри маркетплейса
      </>
    ),
    author: 'GRASS',
  },
  {
    text: (
      <>
        Русскоязычный рынок e&#8209;com давно ждал такого сервиса. Я&nbsp;давно
        пользуюсь зарубежными аналогами SplitTest, но&nbsp;не&nbsp;мог
        использовать их&nbsp;для тестирования товаров, продаваемых в&nbsp;СНГ.
        Теперь эта проблема решена, и&nbsp;я&nbsp;могу спокойно принимать все
        решения, касательно графических изменений
      </>
    ),
    author: 'Роман Матюкевич, селлер',
  },
  {
    text: (
      <>
        Здорово иметь возможность предложить реальным покупателям несколько
        вариантов, чтобы увидеть, какой из&nbsp;них им&nbsp;нравится
        больше&nbsp;и, главное, почему. Сервис не&nbsp;только дает возможность
        для коммуникации с&nbsp;целевой аудиторией, но&nbsp;и&nbsp;выступает
        в&nbsp;роли площадки для первого касания с&nbsp;многими из&nbsp;них
      </>
    ),
    author: 'ZipKidz',
  },
  // {
  //   text: 'Порадовала возможность сохранить черновик теста, чтобы потом его дозаполнить и запустить',
  //   author: 'Socializm',
  // },
  // {
  //   text: <>Вагон информации от&nbsp;реальных пользователей</>,
  //   author: 'Marketikka',
  // },

  // {
  //   text: 'Спасибо за удобный инструмент',
  //   author: 'Нексус Анлимитед',
  // },
  // {
  //   text: (
  //     <>
  //       Читаешь комментарии и&nbsp;удивляешься своим заблуждениям, а все же
  //       оказалось так просто!
  //     </>
  //   ),
  //   author: 'Ю Ай Центрикс',
  // },

  // {
  //   text: 'Получили очень детальный отчет, который с большим интересом изучили',
  //   author: 'Lylo',
  // },

  {
    text: (
      <>Один из передовых методов&nbsp;проверки дизайна на жизнеспособность</>
    ),
    author: 'Атомик Санз',
  },

  {
    text: 'Я получил именно то, что хотел — быстрое решение за смешные деньги',
    author: 'Райт Сайд Свайп',
  },

  {
    text: 'Коллегам нравится. Пользуемся месяц, проект крутой!',
    author: 'Вестсайд Ред',
  },

  {
    text: 'Отличный инструмент для быстрой проверки идей',
    author: 'YAPZ',
  },
  {
    text: 'Результаты теста в точку сошлись с нашими реальными!',
    author: 'Moon Water Brands',
  },

  {
    text: 'Помогло понять, что именно было не так как надо',
    author: 'Стайлтрейс',
  },

  {
    text: (
      <>
        Больше всего удивило, что выигравший вариант может&nbsp;оказаться
        лузером, если в готовом отчете уточнить выборку
      </>
    ),
    author: 'Friendry',
  },
];

export const Tarifs: TTarifItem[] = [
  {
    id: 'start',
    title: 'START',
    priceText: '500 ₽',
    descr: 'Отличный вариант для начала, чтобы попробовать SplitTest',
    data: [
      {
        label: (
          <>
            Опросная группа
            <br /> 15 человек
          </>
        ),
        disabled: true,
      },
      {
        label: (
          <>
            Респонденты <br /> мужчины и женщины
          </>
        ),
        disabled: true,
      },
      {
        label: (
          <>
            Возраст <br /> 25–34 и 35–44 года
          </>
        ),
        disabled: true,
      },
      { label: 'Тест один из двух', disabled: true },
    ],
  },
  {
    id: 'pro',
    title: 'PRO',
    priceText: 'от 1 500 ₽',
    descr: 'Индивидуальная настройка SplitTest под конкретную задачу',
    data: [
      {
        label: (
          <>
            Опросные группы <br /> 50, 100, 200 человек
          </>
        ),
        disabled: false,
      },
      {
        label: (
          <>
            Респонденты <br /> мужчины и/или женщины
          </>
        ),
        disabled: false,
      },
      {
        label: (
          <>
            Возраст <br /> от 25 до 65 лет и старше
          </>
        ),
        disabled: false,
      },
      {
        label: `Тест под задачу`,
        disabled: false,
      },
    ],
  },
  {
    id: 'maximum',
    title: 'MAXIMUM',
    priceText: '9 000 ₽',
    descr: (
      <>
        <p>Максимальное использование всех возможностей SplitTest.</p>
        <p>
          Проведение теста любого типа на&nbsp;опросной группе 500 человек
          с&nbsp;настройкой пола, возраста, уровня образования и&nbsp;выбором
          высокого грейда респондентов.
        </p>
      </>
    ),
    data: [],
  },
];

export const ReviewsItems: TQuoteItem[] = [
  {
    text: (
      <>
        Порадовала возможность сохранить черновик теста, чтобы потом его
        дозаполнить и запустить
      </>
    ),
    author: 'Socializm',
  },
  {
    text: <>Вагон информации от реальных пользователей</>,
    author: 'Marketikka',
  },
  {
    text: <>Спасибо за удобный инструмент</>,
    author: 'Нексус Анлимитед',
  },
  {
    text: 'Читаешь комментарии и удивляешься своим заблуждениям, а все же оказалось так просто!',
    author: 'Ю Ай Центрикс',
  },
  {
    text: 'Получили очень детальный отчет, который с большим интересом изучили',
    author: 'Lylo',
  },
  {
    text: 'Один из передовых методов проверки дизайна на жизнеспособность',
    author: 'Атомик Санз',
  },
  {
    text: 'Я получил именно то, что хотел — быстрое решение за смешные деньги',
    author: 'Райт Сайд Свайп',
  },
  {
    text: 'Коллегам нравится. Пользуемся месяц, проект крутой!',
    author: 'Вестсайд Ред',
  },
  {
    text: 'Отличный инструмент для быстрой проверки идей',
    author: 'YAPZ',
  },
  {
    text: 'Результаты теста в точку сошлись с нашими реальными!',
    author: 'Moon Water Brands',
  },
  {
    text: 'Помогло понять, что именно было не так как надо',
    author: 'Стайлтрейс',
  },
  {
    text: (
      <>
        Больше всего удивило, что выигравший вариант может&nbsp;оказаться
        лузером, если в готовом отчете уточнить выборку
      </>
    ),
    author: 'Friendry',
  },
];
