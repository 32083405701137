import { createSelector } from '@reduxjs/toolkit';
import { TState } from 'store/types';
import { TAuthState } from 'pages/user/store/types';

export const getAuth = (state: TState): TAuthState => state.auth;

export const getIsAuth = createSelector(getAuth, ({ isAuth }) => isAuth);
export const getAuthResponse = createSelector(getAuth, ({ auth }) => auth);
export const getBitrixAuthResponse = createSelector(
  getAuth,
  ({ bitrixAuth }) => bitrixAuth,
);
export const getOzonAuth = createSelector(
  getAuth,
  ({
    ozonAuth: { isOzonAuth, error, response, errorCustomMessage },
    auth: { loading },
  }) => ({
    isOzonAuth,
    error,
    response,
    errorCustomMessage,
    loading,
  }),
);
export const getBitrixAuth = createSelector(
  getBitrixAuthResponse,
  ({ error, response, isBitrixAuth }) => ({
    error,
    response,
    isBitrixAuth,
  }),
);

export const getUserResponse = createSelector(getAuth, ({ user }) => user);
export const getUserBalance = createSelector(
  getAuth,
  ({ user }) => user?.response?.data?.client_balance || '0',
);

export const getUsername = createSelector(
  getAuth,
  ({ user }) => user?.response?.data?.name,
);

export const getUserlastname = createSelector(
  getAuth,
  ({ user }) => user?.response?.data?.surname,
);

export const getUserPartonomic = createSelector(
  getAuth,
  ({ user }) => user?.response?.data?.patronymic,
);

export const getUserMail = createSelector(
  getAuth,
  ({ user }) => user?.response?.data?.email,
);
export const getUserPhone = createSelector(
  getAuth,
  ({ user }) => user?.response?.data?.phone,
);

export const getPartner = createSelector(
  getAuth,
  ({
    partner: { data, initialData, loading, error, isNewPArtnerCreated },
  }) => ({
    partnerData: data,
    partnerInitialData: initialData,
    partnerLoading: loading,
    partnerError: error,
    isNewPArtnerCreated,
  }),
);
